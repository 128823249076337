<script lang="ts">
	import ReviewCard from './ReviewCard.svelte';

	import { swipe } from 'svelte-gestures';
	import { CarouselHelper } from '$lib/helpers/swipe.helper';
	import type { Feedback } from "$lib/domain/feedback.interface";

	export let data: Feedback[] = [];

	let activeIndex = 0;
	const maxCount = data.length;
	const carouselHelper = CarouselHelper.getInstance(maxCount);

	carouselHelper.resetInterval();
	carouselHelper.indexStore.subscribe((index) => (activeIndex = Number(index)));

	export let title: string | '' = 'What our clients say';
	export let description: string | '' = 'See what our clients think about their experience entrusting us with product development';

</script>

<div class="block-bg">
	<div class="container flex-column">
		<div class="section-title flex-column-center">
			<h2>{@html title}</h2>
		</div>
		
		<p class="h5">
			{@html description}
		</p>
		
		<div
			class="container-carousel"
			use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
			on:swipe="{carouselHelper.handleSwipe}">
			{#each data as review, i}
				<ReviewCard review="{review}" visible="{i === activeIndex}" />
			{/each}
		</div>
		
		<ol class="carousel-indicators">
			{#each Array(data.length) as _, i}
				<li
					on:click="{() => carouselHelper.select(i)}"
					on:keydown="{() => carouselHelper.select(i)}"
					class:active="{i === activeIndex}">
					<div class="carousel-bullet">•</div>
				</li>
			{/each}
		</ol>
	</div>
</div>

<style lang="scss">
	@import '$styles/vars.scss';

	.block-bg {
		background: $main-black;
	}

	.container {
		animation: slidein 1s ease-out;

		h2, .h5{
			color: $main-white;
		}

		.h5 {
			padding-top: 12px;
			max-width: 70%;
		}

		&-carousel {
			margin-top: 48px;
			border-radius: 6px;
			min-width: 100%;
		}

      	.carousel-indicators {
        li {
          display: inline-block;

          &.active {
            .carousel-bullet {
              color: $green;
            }
          }
        }

        .carousel-bullet {
          color: $light-green;

          &:hover {
            color: $dark-grey;
          }
        }
      }
	}

	@keyframes slidein {
		from {
			opacity: 0;
			-webkit-transform: translate(-100%);
			transform: translate(-100%);
		}
		to {
			opacity: 1;
		}
	}

	@media (max-width: $portrait-phone-size) {
		.container {
			.h5 {
				max-width: 100%;
			}

			&-carousel {
				margin-top: 0;
				box-sizing: border-box;
			}
		}
	}
</style>
